<template>
  <div class="main">
    <div class="allSubmit">
      <van-button type="primary" size="small" block @click="onSubmit"
        >提交</van-button
      >
    </div>
    <div class="main_cont">
      <div class="checkBox" v-for="(items, index) in locationList" :key="index">
        <div class="title">
          {{ items.ptw_gas_inspection_address }} -- 气体检测
        </div>
        <van-cell-group inset>
          <van-field
            v-model="items.lel"
            label="可燃气体"
            placeholder="可燃气体%LEL 必填"
          >
            <template #button>
              <van-switch
                v-model="items.lel_checked"
                active-color="#ee0a24"
                inactive-color="#dcdee0"
                size="20px"
              />
            </template>
          </van-field>
          <van-field
            v-model="items.ppm"
            label="有毒气体"
            placeholder="有毒气体PPM 必填"
          >
            <template #button>
              <van-switch
                v-model="items.ppm_checked"
                active-color="#ee0a24"
                inactive-color="#dcdee0"
                size="20px"
              />
            </template>
          </van-field>
          <van-field v-model="items.o2" label="o2" placeholder="o2 必填">
            <template #button>
              <van-switch
                v-model="items.o2_checked"
                active-color="#ee0a24"
                inactive-color="#dcdee0"
                size="20px"
              />
            </template>
          </van-field>
          <van-field
            type="textarea"
            v-model="items.commention"
            autosize
            rows="1"
            label="备注"
            placeholder="备注"
          />
        </van-cell-group>
      </div>
      <van-divider
        :style="{
          color: '#000000',
          borderColor: '#1989fa',
          padding: '0 5px',
          margin: '0'
        }"
        >气体检测历史</van-divider
      >
      <van-card
        v-for="(items, index) in list"
        :key="index"
        style="background-color:transparent"
      >
        <template #desc>
          <div class="descBox">
            <van-cell title="检测时间" :value="items.monitor_date" />
            <van-cell title="检测人" :value="items.create_by" />
            <van-cell
              title="分析点位置"
              :value="items.ptw_gas_inspection_address"
            />
            <van-cell title="可燃气体%LEL" :value="items.lel" />
            <van-cell title="有毒气体PPM" :value="items.ppm" />
            <van-cell title="o2" :value="items.o2" />
            <van-cell title="备注" :label="items.commention" />
          </div>
        </template>
      </van-card>
    </div>

    <!-- 签名 -->
    <handwritingHtml
      @writingCommit="writingCommit"
      ref="wirteDialog"
    ></handwritingHtml>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted,ref,getCurrentInstance} from 'vue';
import {Notify} from 'vant';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import handwritingHtml from '../../../components/multiWriting.vue';
export default {
    components:{handwritingHtml},
    setup() {
        const $this = getCurrentInstance();
        const route = useRoute();
        const router = useRouter();
        const list = ref([]);
        const getlist = ()=>{
            Http.get({
                method:'list',
                t:'PTW_GAS_INSPECTION',
                ptw_cetificate_id:route.params.id
            },false,true,true)
            .then(res=>{
                list.value = res;
            })
            .catch(()=>{})
            
        }
        const rtList = (list) =>{
            var coList = [];
            list.forEach(elem=>{
                coList.push({ptw_gas_inspection_address:elem,is_lel_alert:'0',lel:'',is_ppm_alert:'0',ppm:'',is_o2_alert:'0',o2:'',commention:'',ptw_cetificate_id:route.params.id})
            });

            return coList;
        }
        const locationList = ref(rtList(route.params.location.split(',')));
        // 提交
        // PTW_GAS_INSPECTION
        const gps_location = ref('');
        const attachment3 = ref([]);
        const writingCommit = (res) =>{
            
            attachment3.value.push({document_path:res});
            if(attachment3.value.length === 3){
                locationList.value.forEach(elem =>{
                    elem.is_lel_alert = elem.lel_check == true ? 1:0;
                    elem.is_ppm_alert = elem.ppm_check == true ? 1:0;
                    elem.is_o2_alert = elem.o2_check == true ? 1:0;
                    elem.attachment3 = attachment3.value;
                    elem.gps_location = gps_location.value;
                });
                Http.post({
                    method:'query',
                    queryId:215,
                    request:locationList.value
                },false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id);
                })
                .catch(()=>{});
            }
        }
        const onSubmit = ()=>{
            var locationKey = [];
            locationList.value.forEach((items)=>{
                if(items.lel == ''||items.ppm == ''||items.o2==''){
                    locationKey.push(items.ptw_gas_inspection_address);
                }
            });
            if(locationKey.length > 0){
                 Notify({ type: 'danger', message: locationKey.join(',')+'区域气体检测填写不完整' });
                 return false;
            }
            navigator.geolocation.getCurrentPosition(function(position){
                gps_location.value = position.coords.longitude + ','+position.coords.latitude 
                $this.refs.wirteDialog.show();
            }, 
            function(){    
                gps_location.value = '0,0'
                $this.refs.wirteDialog.show();
            });
            
        }
        onMounted(()=>{
            attachment3.value = []
            getlist();
        });
        return {
            rtList,
            onSubmit,
            list,
            locationList,
            writingCommit,
            gps_location
        }
    },
}
</script>

<style scoped lang='less'>
.main_cont{
    height: calc(100% - 40px);
    overflow-y: auto;
    width: 100%;
}
.checkBox{
    width: 95%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    .title{
        padding:10px;
        text-indent: 5px;
        font-size: 15px;
        font-family: 'blod';
        border-bottom: 1px solid #eeeeee;
    }
    .van-radio-group--horizontal{
        width: calc(100% - 20px);
        padding: 0 10px;
        justify-content: space-between;
        .van-radio{
            border-bottom: 0;
        }
    }
    .button{
        width:100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.allSubmit{
    height: 35px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'blod';
    font-size: 16px;
}
</style>