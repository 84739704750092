<template>
  <van-dialog
    :title="
      multiNumber == 1
        ? '气体检测人签名'
        : multiNumber == 2
        ? '现场检查人'
        : multiNumber == 3
        ? '监火人'
        : ''
    "
    @opened="opened"
    v-model:show="handwritingDialog"
    show-cancel-button
    :close-on-popstate="false"
    :before-close="beforeclose"
    width="350px"
    :confirmButtonText="multiNumber == 3 ? '确认' : '下一个'"
  >
    <canvas
      ref="canvasF"
      @touchstart="touchStart"
      @touchmove="touchMove"
      @touchend="touchEnd"
      @mousedown="mouseDown"
      @mousemove="mouseMove"
      @mouseup="mouseUp"
    ></canvas>
    <van-button size="mini" @click="overwrite" type="primary"
      >清空签名</van-button
    >
    <!-- <div style="height:100%;width:100%" v-if="multiNumber == 1">
            <canvas ref="canvasF" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd' @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
            <van-button size="mini" @click="overwrite" type="primary">清空签名</van-button>
        </div>
        <div style="height:100%;width:100%" v-if="multiNumber == 2">
            <canvas ref="canvasF" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd' @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
            <van-button size="mini" @click="overwrite" type="primary">清空签名</van-button>
        </div>
        <div style="height:100%;width:100%" v-if="multiNumber == 3">
            <canvas ref="canvasF" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd' @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
            <van-button size="mini" @click="overwrite" type="primary">清空签名</van-button>
        </div> -->
  </van-dialog>
</template>
<script>
/* eslint-disable */
import {ref,reactive,getCurrentInstance} from 'vue';
import {Notify} from 'vant';
import Http from '../api/http';
export default {
    // props:{
    //     handwritingDialog:{
    //         type:Boolean,
    //         default:true
    //     }
    // },
    setup(props,{emit}) {
        const multiNumber = ref(1)
        const handwritingDialog = ref(false)
        const $this = getCurrentInstance();
        const canvasTxt = ref(null);
        const stageInfo = ref('');
        const imgUrl = ref('');
        const client = reactive({})
        const points = ref([]);
        const startX = ref(0)
        const startY = ref(0)
        const moveY = ref(0)
        const moveX = ref(0)
        const endY = ref(0)
        const endX = ref(0)
        const w = ref(null)
        const h = ref(null)
        const isDown = ref(false);
        const init = () =>{
            let canvas = $this.refs.canvasF
            canvas.height = 300
            canvas.width = 300
            canvasTxt.value = canvas.getContext('2d')
            stageInfo.value = canvas.getBoundingClientRect()
        }
        const opened = () =>{
            init()
        }
        const show = ()=>{
            handwritingDialog.value=true
        }
        // 手机端
        const touchStart = (ev) =>{
            ev = ev
            ev.preventDefault()
            if (ev.touches.length == 1) {
            let obj = {
                x: ev.targetTouches[0].clienX,
                y: ev.targetTouches[0].clientY,
            }
                startX.value = obj.x
                startY.value = obj.y
                canvasTxt.value.beginPath()
                canvasTxt.value.moveTo(startX.value,startY.value)
                canvasTxt.value.lineTo(obj.x, obj.y)
                canvasTxt.value.stroke()
                canvasTxt.value.closePath()
                points.value.push(obj)
            }
        }
        // PC端
        const touchMove = (ev) => {
            ev = ev
            ev.preventDefault()
            if (ev.touches.length == 1) {
                let obj = {
                    x: ev.targetTouches[0].clientX - stageInfo.value.left,
                    y: ev.targetTouches[0].clientY - stageInfo.value.top
                }
                moveY.value = obj.y
                moveX.value = obj.x
                canvasTxt.value.beginPath()
                canvasTxt.value.moveTo(startX.value, startY.value)
                canvasTxt.value.lineTo(obj.x, obj.y)
                canvasTxt.value.stroke()
                canvasTxt.value.closePath()
                startY.value = obj.y
                startX.value = obj.x
                points.value.push(obj)
            }
        }
        const touchEnd = (ev) =>{
            ev = ev
            ev.preventDefault()
            if (ev.touches.length == 1) {
                let obj = {
                    x: ev.targetTouches[0].clientX - stageInfo.value.left,
                    y: ev.targetTouches[0].clientY - stageInfo.value.top
                }
                canvasTxt.value.beginPath()
                canvasTxt.value.moveTo(startX.value, startY.value)
                canvasTxt.value.lineTo(obj.x, obj.y)
                canvasTxt.value.stroke()
                canvasTxt.value.closePath()
                points.value.push(obj)
            }
        }
        //pc
        const mouseDown = (ev)=> {
            ev = ev
            ev.preventDefault()
            if (1) {
                let obj = {
                    x: ev.offsetX,
                    y: ev.offsetY
                }
                startX.value = obj.x
                startY.value = obj.y
                canvasTxt.value.beginPath()
                canvasTxt.value.moveTo(startX.value, startY.value)
                canvasTxt.value.lineTo(obj.x, obj.y)
                canvasTxt.value.stroke()
                canvasTxt.value.closePath()
                points.value.push(obj)
                isDown.value = true
            }
        }
        const mouseMove = (ev)=>{
            ev = ev
            ev.preventDefault()
            if (isDown.value) {
                let obj = {
                    x: ev.offsetX,
                    y: ev.offsetY
                }
                moveY.value = obj.y
                moveX.value = obj.x
                canvasTxt.value.beginPath()
                canvasTxt.value.moveTo(startX.value, startY.value)
                canvasTxt.value.lineTo(obj.x, obj.y)
                canvasTxt.value.stroke()
                canvasTxt.value.closePath()
                startY.value = obj.y
                startX.value = obj.x
                points.value.push(obj)
            }
        }
        const mouseUp = (ev) =>{
            ev = ev 
            ev.preventDefault()
            if (1) {
                let obj = {
                    x: ev.offsetX,
                    y: ev.offsetY
                }
                canvasTxt.value.beginPath()
                canvasTxt.value.moveTo(startX.value, startY.value)
                canvasTxt.value.lineTo(obj.x, obj.y)
                canvasTxt.value.stroke()
                canvasTxt.value.closePath()
                points.value.push(obj)
                points.value.push({x: -1, y: -1})
                isDown.value = false
            }
        }

        //验证canvas画布是否为空
        const isCanvasBlank = () =>{
            var blank = document.createElement('canvas');//系统获取一个空canvas对象
            blank.width = $this.refs.canvasF.width;
            blank.height = $this.refs.canvasF.height;
            return blank.toDataURL() == $this.refs.canvasF.toDataURL();//比较值相等则为空
        }
        //调用
        //非空验证
        const checkEmpty = () => {
            if(isCanvasBlank()){
                return false;
            }else{
                return true;
            }
        }    
        const overwrite = ()=>{
            canvasTxt.value.clearRect(0, 0, 300, 300)
            points.value = []
        }
        const beforeclose = (action) =>{
            new Promise(() => {
               if (action === 'confirm') {
                   if(!checkEmpty()){
                       return Notify({ type: 'danger', message: `请签名！` });
                   }else{
                        imgUrl.value=$this.refs.canvasF.toDataURL();
                        var file = Http.dataURLtoFile(imgUrl.value);
                        let formData = new FormData();
                        formData.append('file', file, file.name);
                        Http.fileuoload(formData)
                        .then(res=>{
                            if(multiNumber.value == 3){
                                handwritingDialog.value=false;
                            }else{
                                init()
                                multiNumber.value = multiNumber.value + 1
                                
                            }
                            emit('writingCommit',res)
                        })
                        .catch(()=>{})
                   }
                } else {
                    // 拦截取消操作
                    handwritingDialog.value=false;
                }
            });
        }
        return {
            overwrite,
            canvasTxt,
            stageInfo,
            imgUrl,
            client,
            points,
            startX,
            startY,
            moveY,
            moveX,
            endY,
            endX,
            w,
            h,
            isDown,
            opened,
            touchStart,
            touchMove,
            touchEnd,
            mouseDown,
            mouseMove,
            mouseUp,
            beforeclose,
            handwritingDialog,
            show,
            multiNumber
        }
    },
}
</script>